import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from 'pages/home/Home';
import About from 'pages/about/About';
import Calendar from 'pages/calendar/Calendar';
import CalendarEvent from 'pages/calendar/CalendarEvent';
import EditEvent from 'pages/calendar/EditEvent';
import Shop from 'pages/shop/Shop';
import Songs from 'pages/songs/Songs';
import Links from 'pages/links/Links';
import Login from 'pages/login/Login';
import Downloads from 'pages/downloads/Downloads';
import Photos from 'pages/photos/Photos';
import PhotoGallery from 'pages/photos/PhotoGallery';
import MainLayout from './layout/MainLayout';
import HomeLayout from './layout/HomeLayout';

const router = createBrowserRouter([{
	element: <HomeLayout />,
	children: [{
		path: '/',
		element: <Home />
	}]
}, {
	element: <MainLayout />,
	children:[{
		path: '/about',
		element: <About />
	}, {
		path: '/calendar/:date',
		element: <CalendarEvent />
	}, {
		path: '/calendar/:date/edit',
		element: <EditEvent />
	}, {
		path: '/calendar',
		element: <Calendar />
	}, {
		path: '/shop',
		element: <Shop />
	}, {
		path: '/links',
		element: <Links />
	}, {
		path: '/login',
		element: <Login />
	}, {
		path: '/downloads',
		element: <Downloads />
	}, {
		path: '/photos',
		element: <PhotoGallery />
	}, {
		path: '/photos/:id',
		params: {
			id: 'id'
		},
		element: <Photos />
	}, {
		path: '/song-database',
		element: <Songs />
	}]
}]);

const App = () => {
	return (
		<RouterProvider router={router}>
		</RouterProvider>
	);
};

export default App;

import config from '/src/config.js';

export default {
	name: 'DOWNLOADS',
	actions: {
		FETCH_DOWNLOADS: {
			url: 'api/downloads.php',
			onSuccess: (data) => {
				// URI start with slash, so no need to prefix
				data.forEach((download) => {
					download.src = `${config.imageBasePath || ''}/${download.src}`;
					download.image = `${config.imageBasePath || ''}/${download.image}`;
				});
				return data;
			}
		}
	}
};

import config from '/src/config.js';

export default {
	name: 'LINKS',
	actions: {
		FETCH_LINKS: {
			url: 'api/links.php'
		}
	}
};

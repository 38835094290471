import config from '/src/config.js';
import { addEventMetadata } from '../utils/EventUtils.js';

export default {
	name: 'EVENT',
	initialState: null,
	actions: {
		FETCH_EVENT: {
			url: (action) => ([
				`${config.imageBasePath}/api/events.php/${action.payload}`,
				{
					method: 'GET'
				}
			]),
			onSuccess: (data) => addEventMetadata(data)
		}
	}
};

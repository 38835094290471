import config from '/src/config.js';

export default {
	name: 'MESSAGES',
	actions: {
		FETCH_MESSAGES: {
			url: 'api/messages.php'
		}
	}
};

import config from '/src/config.js';

export default {
	name: 'PHOTOS',
	actions: {
		FETCH_PHOTOS: {
			url: (action) => [
				`${config.imageBasePath}/api/photos.php?view=${action.payload}`
			],
			onSuccess: (data, { action }) => {
				// URI start with slash, so no need to prefix
				data.forEach((photo) => {
					photo.src = `${config.imageBasePath || ''}${photo.src}`;
					photo.thumbnail = `${config.imageBasePath || ''}${photo.thumbnail}`;
				});
				return {
					id: action.payload,
					photos: data
				};
			}
		}
	},
	reducer: (state, action) => {
		switch (action.type) {
		case 'FETCH_PHOTOS':
			return {
				...state,
				photos: {
					finishedLoading: false
				}
			}
		case 'FETCH_PHOTOS_SUCCESS':
			return {
				...state,
				photos: {
					finishedLoading: true,
					data: {
						...state.photos.data,
						[action.payload.id]: action.payload.photos
					}
				}
			};
		default:
			return state;
		}
	}
};

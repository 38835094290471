import config from '/src/config.js';

export default {
	name: 'GALLERY',
	actions: {
		FETCH_GALLERY: {
			url: 'api/photos-gallery.php',
			onSuccess: (data) => {
				// URI start with slash, so no need to prefix
				data.forEach((photo) => {
					photo.image = `${config.imageBasePath || ''}${photo.image}`;
				});
				return data;
			}
		}
	}
};

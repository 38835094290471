import React from 'react';
import contexts from './contexts';

const { Provider: DownloadsProvider } = contexts.downloads;
const { Provider: EventsProvider } = contexts.events;
const { Provider: EventProvider } = contexts.event;
const { Provider: GalleryProvider } = contexts.gallery;
const { Provider: MessagesProvider } = contexts.messages;
const { Provider: PhotosProvider } = contexts.photos;
const { Provider: ShopProvider } = contexts.shop;
const { Provider: LinksProvider } = contexts.links;
const { Provider: LoginProvider } = contexts.login;

const GlobalContext = ({ children }) => {
	return (
		<EventProvider>
			<EventsProvider>
				<MessagesProvider>
					<PhotosProvider>
						<GalleryProvider>
							<ShopProvider>
								<DownloadsProvider>
									<LinksProvider>
										<LoginProvider>
											{children}
										</LoginProvider>
									</LinksProvider>
								</DownloadsProvider>
							</ShopProvider>
						</GalleryProvider>
					</PhotosProvider>
				</MessagesProvider>
			</EventsProvider>
		</EventProvider>
	);
};

export default GlobalContext;
